import Image from "next/image";
import beam from "@/images/hero/beam.png";
import {Container} from "@/components/Container";
import {ArrowDownRightIcon} from "@/images/ArrowDownRightIcon";
import Link from "next/link";
import weeztixImage from "@/images/clients/weeztix.svg";
import fritsphilipsImage from "@/images/clients/frits-philips.png";
import frontaalImage from "@/images/clients/frontaal.svg";
import tfinImage from "@/images/clients/tfin.png";
import chasingImage from "@/images/clients/chasing.svg";
import adeImage from "@/images/clients/ade.png";
import beamImage from "@/images/about/beam.png";
import {useTranslations} from "next-intl";

export function MyApproach() {
    const t = useTranslations('MyApproach');

    return <section className="" id="aanpak">
        <Image src={beamImage} alt="" className="absolute -top-[20rem] left-0 z-0
            w-[75.4rem] md:w-[90rem] max-w-none
        "/>
        <div className="bg-midnight section-rounded-b ">
            {/*<Image src={beam} alt="" className="absolute -top-[20rem] left-1/2 -ml-[60rem] w-[163.125rem] max-w-none sm:-ml-[67.5rem]" priority/>*/}


            <Container className="py-20 md:py-32 text-center relative overflow-hidden">
                <h1 className="text-6xl md:text-7xl font-semibold bg-clip-text py-5 font-gradient font-effect-hero">
                    {t('title')}
                </h1>
                <p className="my-5 text-xl md:text-2xl max-w-xl mx-auto opacity-70">
                    {t('description')}
                </p>

                <Link
                    href="#projecten"
                    className="text-secondary font-semibold text-2xl inline-flex items-center gap-4"
                >
                    <span>{t('result_button')}</span>

                    <div
                        className="w-12 h-12 inline-flex items-center justify-center bg-secondary bg-opacity-20 rounded-full border border-white border-opacity-10 backdrop-blur-lg">
                        <ArrowDownRightIcon/>
                    </div>
                </Link>

                <div className="grid grid-cols-3 md:grid-cols-6 gap-4 items-center mt-14">
                    {[adeImage, fritsphilipsImage, frontaalImage, weeztixImage, tfinImage, chasingImage].map((image, index) =>
                        <Image key={index} src={image} alt=""
                               className="w-40 h-auto opacity-40 hover:opacity-100 transition-opacity brightness-0 invert"/>
                    )}
                </div>
            </Container>
        </div>
    </section>
}
