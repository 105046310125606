import electivesImage from "@/images/projects/electives/electives.png";
import {NextjsIcon} from "@/images/technologies/NextjsIcon";
import {ReactIcon} from "@/images/technologies/ReactIcon";
import {LaravelIcon} from "@/images/technologies/LaravelIcon";
import {ShopifyIcon} from "@/images/technologies/ShopifyIcon";
import roelImage from "@/images/projects/frontaal/testimonials/roel.jpeg";
import guusImage from "@/images/projects/frontaal/testimonials/guus.jpg";

import nadiaImage from '@/images/projects/tfin/testimonials/nadia.jpeg'
import basImage from '@/images/projects/tfin/testimonials/bas.jpeg'
import {
    ChartPieIcon,
    CreditCardIcon,
    CursorArrowRaysIcon,
    EnvelopeIcon,
    LinkIcon,
    LockClosedIcon, WrenchScrewdriverIcon
} from "@heroicons/react/24/outline";

import crownestImage from '@/images/projects/tfin/crownest.png';
import exerciseImage from '@/images/projects/tfin/exercise.png';
import exerciseTrackImage from '@/images/projects/tfin/exercise-track.png';
import exerciseTrack2Image from '@/images/projects/tfin/exercise-track2.png';
import boardImage from '@/images/projects/tfin/board.png';
import statsImage from '@/images/projects/tfin/stats.png';

import choiceCalendarImage from '@/images/projects/electives/choiceCalendar.png';
import choiceImage from '@/images/projects/electives/choice.png';
import choiceMobileImage from '@/images/projects/electives/mobile.png';
import robImage from '@/images/projects/electives/testimonials/rob.jpeg';
import petraImage from '@/images/projects/electives/testimonials/pertra.jpeg';
import richardImage from '@/images/projects/electives/testimonials/richard.jpeg';

import frontaalHomeImage from '@/images/projects/frontaal/home.png';
import frontaalShopImage from '@/images/projects/frontaal/shop.png';
import frontaalBarImage from '@/images/projects/frontaal/bar.png';
import frontaalProductImage from '@/images/projects/frontaal/product.png';

import amlo1Image from '@/images/projects/amlo/1.png';
import amlo2Image from '@/images/projects/amlo/2.png';
import amlo3Image from '@/images/projects/amlo/3.png';
import amlo4Image from '@/images/projects/amlo/4.png';
import amlo5Image from '@/images/projects/amlo/5.png';
import maartenImage from '@/images/projects/amlo/testimonials/maarten.jpeg';
import maudImage from '@/images/projects/amlo/testimonials/maud.png';

const nextJs = {
    icon: NextjsIcon,
};
const react = {
    icon: ReactIcon,
    title: 'React'
};
const laravel = {
    icon: LaravelIcon,
    title: 'Laravel'
};
const charts = (t) => ({
    icon: ChartPieIcon,
    title: t('charts')
});
const dragAndDrop = {
    icon: CursorArrowRaysIcon,
    title: 'Drag & drop'
};
const authentication = (t) => ({
    icon: LockClosedIcon,
    title: t('authentication')
});
const emails = {
    icon: EnvelopeIcon,
    title: 'Emails'
};
const api = (t) => ({
    icon: LinkIcon,
    title: t('api')
});
const shopify = (t) => ({
    icon: ShopifyIcon,
    title: t('shopify')
})
const payments = (t) => ({
    icon: CreditCardIcon,
    title: t('payments')
})
const hardware = (t) => ({
    icon: WrenchScrewdriverIcon,
    title: t('hardware')
})

const tfin = (t) => ({
    image: exerciseImage,
    carousel: [
        {
            src: exerciseImage,
            alt: 'Oefeningen scherm.',
        },
        {
            src: crownestImage,
            alt: 'Kraaiennest scherm.',
        },
        {
            src: boardImage,
            alt: 'Bord scherm.',
        },
        {
            src: exerciseTrackImage,
            alt: 'Oefeningen en tracks overzicht.',
        },
        {
            src: exerciseTrack2Image,
            alt: 'Oefeningen en tracks ander overzicht.',
        },
        {
            src: statsImage,
            alt: 'Scherm met statistieken van het systeem.',
        },
    ],
    shortTitle: t('tfin.shortTitle'),
    title: t('tfin.title'),
    shortDescription: t('tfin.shortDescription'),
    description: t('tfin.description'),
    technologies: [laravel, react, authentication(t), emails, charts(t), dragAndDrop],
    testimonials: [
        {
            image: basImage,
            name: 'Bas Mélotte',
            function: t('tfin.testimonials.bas.function'),
            text: t('tfin.testimonials.bas.text')
        },
        {
            image: nadiaImage,
            name: 'Nadia Lucassen',
            function: t('tfin.testimonials.nadia.function'),
            text: t('tfin.testimonials.nadia.text')
        },
    ]
});

const electives = (t) => ({
    image: choiceCalendarImage,
    carousel: [
        {
            src: choiceCalendarImage,
            alt: 'Scherm waar de keuzevak kalender te zien is.',
        },
        {
            src: choiceImage,
            alt: 'Scherm waar leerlingen keuzes kunnen maken.',
        },
        {
            src: choiceMobileImage,
            alt: 'Scherm waar leerlingen keuzes kunnen maken op mobiel.',
        },
    ],
    shortTitle: t('electives.shortTitle'),
    title: t('electives.title'),
    shortDescription: t('electives.shortDescription'),
    description: t('electives.description'),
    technologies: [laravel, api(t), authentication(t), charts(t)],
    testimonials: [
        {
            image: robImage,
            name: 'Rob Heijmans',
            function: t('electives.testimonials.rob.function'),
            text: t('electives.testimonials.rob.text')
        },
        // {
        //     image: petraImage,
        //     name: 'Petra Verdonschot',
        //     function: 'Basisroostermaker',
        //     text: 'Have a nice day, Lorenso! We’ve try to create amazing website for you!'
        // },
        // {
        //     image: richardImage,
        //     name: 'Richard Verdonschot',
        //     function: 'Systeembeheerder',
        //     text: 'Have a nice day, Lorenso! We’ve try to create amazing website for you!'
        // },
    ]
});

const frontaal = (t) => ({
    image: frontaalShopImage,
    carousel: [
        {
            src: frontaalHomeImage,
            alt: 'Prachtige frontaal home pagina.',
        },
        {
            src: frontaalShopImage,
            alt: 'Frontaal bier shop pagina.',
        },
        {
            src: frontaalBarImage,
            alt: 'Frontaal bar pagina',
        },
        {
            src: frontaalProductImage,
            alt: 'Frontaal product detail pagina waar een biertje te zien is.',
        },
    ],
    shortTitle: t('frontaal.shortTitle'),
    title: t('frontaal.title'),
    shortDescription: t('frontaal.shortDescription'),
    description: t('frontaal.description'),
    technologies: [nextJs, shopify(t), api(t), authentication(t), emails, charts(t)],
    testimonials: [
        // {
        //     image: roelImage,
        //     name: 'Roel Buckens',
        //     function: 'Eigenaar & Brouwer',
        //     text: 'Lorenso Create a unique service for us with personal care and long testimonials about project with large text. Lorenso Create a unique service for us with personal care and long testimonials about project with large text'
        // },
        {
            image: guusImage,
            name: 'Guus van Nijnatten',
            function: t('frontaal.testimonials.guus.function'),
            text: t('frontaal.testimonials.guus.text')
        },
    ]
});

const amlo = (t) => ({
    image: amlo2Image,
    carousel: [
        {
            src: amlo1Image,
            alt: '',
        },
        {
            src: amlo2Image,
            alt: '',
        },
        {
            src: amlo3Image,
            alt: '',
        },
        {
            src: amlo4Image,
            alt: '',
        },
        {
            src: amlo5Image,
            alt: '',
        },
    ],
    shortTitle: t('amlo.shortTitle'),
    title: t('amlo.title'),
    shortDescription: t('amlo.shortDescription'),
    description: t.raw('amlo.description'),
    technologies: [payments(t), hardware(t), authentication(t), emails, charts(t)],
    testimonials: [
        {
            image: maartenImage,
            name: 'Maarten Erdtsieck',
            function: t('amlo.testimonials.maarten.function'),
            text: t('amlo.testimonials.maarten.text')
        },
        {
            image: maudImage,
            name: 'Maud van der Vleuten',
            function: t('amlo.testimonials.maud.function'),
            text: t('amlo.testimonials.maud.text')
        },
    ]
});

export const getHeroProjects = (t) => [
    amlo(t),
    tfin(t),
    frontaal(t),
]

export default function getProjects(t) {
    return [
        amlo(t),
        tfin(t),
        electives(t),
        frontaal(t),
        // Go ZERO
        // Weeztix apps
        // Bijlesplein
    ]
}
